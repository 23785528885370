

/* External packages */
import React from 'react';

/* Local components */
import ButtonWithImageTooltip from "../Buttons/ButtonWithImageTooltip";

/* Imagen */
import logo from "../../assest/img/button-logo.svg";
import layer from "../../assest/img/layer.svg";
import graph from "../../assest/img/graph.svg";
import exit from "../../assest/img/exit.svg";

/**
 * NavBar Component.
 * @param {Object} props - Component properties
 * @param {function} props.visibleLayers - Function that is executed when toggling layer visibility.
 * @param {function} props.visibleDashBoard - Function that is executed when toggling the visibility of the dashboard.
 * @param {boolean} props.isVisibleLayers - State indicating whether the layers are visible or not.
 * @param {boolean} props.isVisibleDashBoard - Status that indicates whether the dashboard is visible or not.
 * @param {boolean} props.layerData - Status indicating whether layer data is available.
 * @param {boolean} props.dashBoard - Status that indicates whether the dashboard is enabled.
 * @param {function} props.operationLogout - Callback function that is executed when the exit button is clicked.
 * @returns {JSX.Element} Navigation bar component.
 */
const NavBar = ({ visibleLayers, visibleDashBoard, isVisibleLayers, isVisibleDashBoard, layerData, dashBoard, operationLogout }) => {

    const closedLayers = () =>{
        visibleLayers()
    }

    const closedDashBoard = () =>{
        visibleDashBoard()
    }

    const logout = () => {
        operationLogout(true)
    };

    return (
        <div className='NavBar'>
            <div className={`NavBar--Top NavBar--${isVisibleLayers}`}>
                {layerData && <ButtonWithImageTooltip img={layer} styles={`layer--${isVisibleLayers}`} text="Capas" operation={closedLayers} />}
                {dashBoard && <ButtonWithImageTooltip img={graph} styles={`graph--${isVisibleDashBoard}`} text="Gráficas" operation={closedDashBoard} />}
                <ButtonWithImageTooltip img={exit} styles="exit" text="Salir" operation={logout}/>
            </div>
            <img src={logo} alt="Logo" className='logo'/>
        </div>
    )
}

export default NavBar;