
/* External packages */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

/* Local components */
import MapLeaflet from '../../components/MapLeaflet/MapLeaflet';
import DashBoard from '../../components/DashBoard/DashBoard';
import NavBar from '../../components/NavBar/NavBar';
import LayersControl from '../../components/LayersControl/LayersControl';
import ButtonWithImageTooltip from '../../components/Buttons/ButtonWithImageTooltip';
import ZoneControl from '../../components/ZoneControl/ZoneControl';
import LogoutModal from '../../components/LogoutModal/LogoutModal';

/* Local JS */
import axiosInstance from "../../utils/api" // Ensure you have axiosInstance set up properly

/* Img */
import expand from "../../assest/img/expand.svg";


const PageMap = () => {
    /* Filter Zone */
    const [filteredZones, setFilteredZones] = useState({});
    const [flyToCoordinates, setFlyToCoordinates] = useState([40.077, -3.68]);
    const [geometryWithBBox, setGeometryWithBBox ]= useState(null);
    /* Load Data */
    const [zonesData, setZonesData] = useState(null);
    const [layerData, setLayerData] = useState(null);
    /* Layers */
    const [activeLayers, setActiveLayers] = useState([]); // Layers in control layers
    const [activeLayerData, setActiveLayerData] = useState([]); // Data leyers in map
    /* Control Visible */
    const [visibleLayerControl, setVisibleLayerControl] = useState(false);
    const [visibleDashBoardControl, setVisibleDashBoardControl] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);

    /* Logout */
    const [logout, setLogout] = useState(null);

    const navigate = useNavigate();
    const navigateRef = useRef(navigate);

    const activeFullScreen = () => {
        if (!isFullScreen) {
            // Ingresar en modo pantalla completa
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) { // Firefox
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari y Opera
                document.documentElement.webkitRequestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
                document.documentElement.msRequestFullscreen();
            }
        } else {
            // Salir del modo pantalla completa
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari y Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
        }

        setIsFullScreen(!isFullScreen);
    };

    useEffect(() => {
        // Función asincrónica para obtener los endpoints y luego los datos de los layers
        const fetchLayerData = async () => {
            try {
                const token = sessionStorage.getItem('access');

                if (!token) {
                    navigateRef.current('/');
                    return;
                }

                // Primero, obtener los endpoints
                const response = await axiosInstance.get('/layers');

                const endpoints = response.data.layers;

                // Crear un objeto para almacenar los datos de cada layer
                const data = {};

                // Promesas para obtener los datos de cada endpoint
                const promises = Object.keys(endpoints).map(async (key) => {

                    const result = await axiosInstance.get(`/${key}/`);

                    data[key] = {
                        endpoint: key,  // Guardar el endpoint
                        label: endpoints[key],
                        data: result.data         // Guardar los datos recibidos
                    };

                    if (key === "zones") {
                        setZonesData(data[key])
                    }
                });

                // Esperar a que todas las promesas se resuelvan
                await Promise.all(promises);

                // Actualizar el estado con los datos de los layers
                setLayerData(data);
            } catch (error) {
                console.error('Error fetching layer data');
            }
        };

        fetchLayerData();
    }, []);
    
    const visibleDashBoard = () => {
        if (layerData) {
            setVisibleDashBoardControl(!visibleDashBoardControl)
        }
    }

    const visibleLayers = () => {
        setVisibleLayerControl(!visibleLayerControl)
    }

    /* Active Layers in layerControl */
    const toggleLayer = (layerName) => {
        setActiveLayers((prevLayers) =>
            prevLayers.includes(layerName)
                ? prevLayers.filter((name) => name !== layerName)
                : [...prevLayers, layerName]  
        );
    };

    useEffect(() => {
        /* Active Layers in Map */
        const updateActiveLayerData = (activeLayers) => {
            const filteredData = activeLayers.reduce((acc, layerName) => {
                acc[layerName] = layerData[layerName];
                return acc;
            }, {});
    
            setActiveLayerData(filteredData);
        };

        updateActiveLayerData(activeLayers);
    }, [activeLayers, layerData]);
    
    /* Filter Zone */
    const handleZoneChange = ({ zoneName, zoneTimeRange, magnitudeFilter, coordinates, geometry }) => {
        const newFilteredZones = {
            name: zoneName ? zoneName : false,
            time: zoneTimeRange ? zoneTimeRange : false,
            magnitude: magnitudeFilter ? magnitudeFilter : false
        };
        setFlyToCoordinates(coordinates)
        setGeometryWithBBox(geometry);
        
        setFilteredZones(newFilteredZones);

        if (!activeLayers.includes("earthquakes")) {
            setActiveLayers((prevLayers) => {
                const updatedLayers = [...prevLayers, "earthquakes"];
                return updatedLayers;
            });
        }
    };

    useEffect(() => {
        if (layerData && layerData["earthquakes"]) {
            let options = `${layerData["earthquakes"].endpoint}/?`;

            if (filteredZones.time) {
                // Convertir filteredZones.time a formato ISO para comparar solo la fecha
                const zoneDate = new Date(filteredZones.time).toISOString().split('T')[0]; // fecha de filteredZones.time
                options += `start_date=${zoneDate}`;
            }

            if (filteredZones.name) {
                options += `&zone=${filteredZones.name}`
            }

            if (filteredZones.magnitude) {
                options += `&min_magnitude=${filteredZones.magnitude[0]}`
                if (filteredZones.magnitude.length === 2) {
                    options += `&max_magnitude=${filteredZones.magnitude[1]}`
                } 
            }

            const refreshErthquakes = async () => { 
                try {
                    const response = await axiosInstance.get(options);

                    const datatemp = layerData
                    datatemp["earthquakes"].data = response.data

                    setLayerData(datatemp)
                } catch (error) {
                    console.error("Error fetching earthquakes");
                }
            }

            refreshErthquakes()
        }
    }, [filteredZones]); 

    const onConfirmLogout = () => {
        sessionStorage.removeItem('access');
        sessionStorage.removeItem('refresh');
        navigate('/');
    }

    const onCancelLogout = () => {
        setLogout(!logout)
    }

    return (
        <>
            {/* Map */}
            {activeLayerData && <MapLeaflet 
                activeLayers = {activeLayerData} 
                coordinates = {flyToCoordinates} 
                geometry  ={geometryWithBBox}
                setGeometryWithBBox = {setGeometryWithBBox}
                /> }
            {/* izquierda */}
            <NavBar 
                visibleLayers = {visibleLayers} 
                visibleDashBoard = {visibleDashBoard} 
                isVisibleLayers = {visibleLayerControl} 
                isVisibleDashBoard = {visibleDashBoardControl}
                layerData = {layerData ? true : false}
                dashBoard = {layerData && layerData.earthquakes ? true : false}
                operationLogout = {setLogout}
                />
            {visibleLayerControl && <LayersControl 
                layerData = {layerData} 
                operation = {toggleLayer} 
                visible = {visibleLayers} 
                activeLayers = {activeLayers} 
                />} 
            {visibleDashBoardControl ? layerData["earthquakes"] && <DashBoard 
                visible = {visibleDashBoard} 
                endPoint = {layerData.earthquakes.endpoint} 
                inZone = {zonesData} 
                /> : null} 
            {/* top setting */} 
            <div className='top--setting' >
                {<ZoneControl 
                    zoneData = {zonesData} // zonesData.data
                    onZoneChange = {handleZoneChange} 
                    earthquakesData = {layerData && layerData.earthquakes ? true : false} 
                    /> }   
                <ButtonWithImageTooltip 
                    img = {expand} 
                    styles = '' 
                    text = "Pantalla Completa"
                    operation = {activeFullScreen}
                    />
            </div>
            <LogoutModal isOpen={logout} onConfirm={onConfirmLogout} onCancel={onCancelLogout} />
        </>
    )
}


export default PageMap;