
/* External packages */
import React, { useState } from 'react';

/* Img */
import visibility from "../../assest/img/visibility.svg";
import visibilityHover from "../../assest/img/visibility_focus.svg";
import visibilityOff from "../../assest/img/visibility_off.svg";

/**
 * PasswordInput Component
 * @param {Object} props - The properties of the component.
 * @param {string} props.value - Current value of the password entry.
 * @param {function} props.onChange - Callback function that is executed when the input value changes.
 * @param {string} props.placeholder - Placeholder text for the entry.
 * @param {string} props.styles - Additional CSS classes to customize the style.
 * @param {function} props.handleKeyDown - Optional callback function that is executed when a key is pressed.
 * @returns {JSX.Element} Password entry component with visibility button.
 */
const PasswordInput = ({ value, onChange, placeholder, styles, handleKeyDown }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const toggleShowPassword = (event) => {
        if (event.type === "click") {
            setShowPassword((prevState) => !prevState);
        }
    };

    const onKeyDownHandler = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the "Enter" key from executing the submit
        }
    };

    return (
        <div className='password--input--container'>
            <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={value}
                autoComplete="current-password" 
                placeholder={placeholder}
                onChange={onChange}
                onKeyDown={onKeyDownHandler}
                onMouseEnter={() => setIsHovered(true)} 
                onMouseLeave={() => setIsHovered(false)}
                className={`${styles} login--focus--${showPassword}`}
                required
            />

            <img 
                src={showPassword ? visibilityOff : (isHovered ? visibilityHover : visibility)} 
                className={`button--visibility--${showPassword}`} 
                onClick={toggleShowPassword} 
                alt="toggle visibility"/>
        </div>
    );
};

export default PasswordInput;
