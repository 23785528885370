
/* External packages */
import axios from 'axios';

/**
 * Axios instance with base configuration and authentication token interceptor.
 *
 * This module creates an Axios instance with a base URL from environment variables. It also sets up an
 * interceptor to include an authentication token (if available) in the headers of every request.
 *
 * @module api
 */
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Accept-Language': 'es-ES',
    },
});


/**
 * Request interceptor to add the authentication token to request headers.
 *
 * This interceptor checks for the presence of a token in sessionStorage and, if found, adds it to the
 * Authorization header of the outgoing request.
 *
 * @function requestInterceptor
 * @param {object} config - The Axios request configuration object.
 * @returns {object} - The modified Axios request configuration object with the Authorization header.
 * @throws Will reject the promise if an error occurs.
 */
axiosInstance.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('access');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * Axios response interceptor that handles access token renewal.
 * This interceptor catches all responses from Axios.
 * If the response is a 401 error (unauthorized) and the original request has not been retried,
 * it attempts to renew the access token and retries the original request.
 *
 * @param {Object} response - The Axios response object.
 * @returns {Object} response - The same response if no errors occur.
 * 
 * @param {Object} error - The Axios error object.
 * @param {Object} error.config - The original Axios request configuration.
 * @param {Object} error.response - The Axios response object containing the HTTP status code.
 * @param {number} error.response.status - The HTTP status code of the response.
 * @returns {Promise} - A promise that resolves with the response of the retried request or rejects with the original error.
 */
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // Verifica si la respuesta es 401 y que no hemos reintentado ya la solicitud
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // Intentar renovar el token de acceso
            try {
                const token = sessionStorage.getItem('access');
                
                if (isTokenExpired(token)) {
                    await refreshAccessToken(); // Esto solo funcionará si el refresh token es válido

                    const newToken = sessionStorage.getItem('access')

                    if (token !== newToken) {
                        // Actualiza el header con el nuevo token de acceso
                        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

                        // Reintenta la solicitud original con el nuevo access token
                        return axiosInstance(originalRequest);
                    }
                }

                redirectToLogin();

            } catch (refreshError) {
                // Si fallamos al refrescar el token (probablemente porque el refresh token expiró)
                if (refreshError.response && (refreshError.response.status === 401 || refreshError.response.status === 403)) {
                    console.error('Refresh token expired or invalid. Logging out.');

                    // Redirige al usuario a la página de inicio de sesión
                    redirectToLogin();
                }

                // Si hubo algún otro error, lo rechazamos
                return Promise.reject(refreshError);
            }
        }

        // Si no es un error 401 o ya hemos reintentado la solicitud, rechazamos el error
        return Promise.reject(error);
    }
);

/**
 * Function to refresh the access token.
 * This function retrieves the refresh token from local storage,
 * makes a request to the refresh endpoint to obtain a new access token,
 * and updates the access token in local storage.
 *
 * @async
 * @function refreshAccessToken
 * @returns {Promise<void>} - A promise that resolves when the token has been refreshed or rejects with an error.
 */
const refreshAccessToken = async () => {
    try {
        const refresh = sessionStorage.getItem('refresh');

        if (refresh && isTokenExpired(refresh)) {
            const response = await axiosInstance.post('/auth/token/refresh/', { refresh });
            const { access } = response.data;

            // Guarda el nuevo token de acceso
            sessionStorage.setItem('access', access);
            console.log("Token de acceso actualizado:", access);
        } else {
            console.warn("Refresh token no encontrado, redirigiendo al login.");
            redirectToLogin();
        }
    } catch (err) {
        if (err.response) {
            const statusCode = err.response.status;
            if (statusCode === 401 || statusCode === 403) {
                console.error("Refresh token expirado o inválido. Redirigiendo al login.");
                redirectToLogin();
            } else {
                console.error("Error al refrescar el token:", err.response.data);
            }
        } else {
            console.error("Error de red o del servidor al refrescar el token:", err.message);
        }
    }
};

const redirectToLogin = () => {
    if (window.location.pathname !== '/') { // Verifica que no estás ya en la página de login
        // Elimina tokens y redirige al login
        sessionStorage.removeItem('access');
        sessionStorage.removeItem('refresh');
        window.location.href = '/'; // Redirige a login
    }
};

const isTokenExpired = (token) => {
    if (!token) return true;
    const payload = JSON.parse(atob(token.split('.')[1])); // Decodificar la carga útil del token
    const expiration = payload.exp * 1000;
    return Date.now() >= expiration;
};

export default axiosInstance;
