
/* External packages */
import React, { useEffect, useState } from 'react';

/* Local components */
import Button from '../Buttons/Button';
import SelectBox from '../SelectBox/SelectBox';
import CustomDatepicker from '../CustomDatepicker/CustomDatepicker';
import DropdownButton from '../DropdownButton/DropdownButton';

/* Img */
import imgDownload from "../../assest/img/download.svg";



const controlTimeOptions = [
    {name: "Diario", value: 1},
    {name: "Mensual", value: 30},
    {name: "Anual", value: 365},
]

const controlDonwload = [
    {name: "JPG", value: "jpg"},
    {name: "PNG", value: "png"},
    {name: "CSV", value: "csv"},
    {name: "EXCEL", value: "excel"},
]


/**
 * DashBoardControl Component
 * 
 * This component provides the control panel for filtering earthquake data on the dashboard. 
 * It includes options to set the geographical zone, date range, data granularity, 
 * event type (magnitude or count), and download options.
 * 
 * @param {Object} props - Component properties.
 * @param {Object} props.zone - GeoJSON object representing the selected zone.
 * @param {function} props.onFilterChange - Callback to update the main dashboard filter settings.
 * @param {function} props.onEventChange - Callback to toggle between event count and magnitude.
 * @param {function} props.onDownloadChange - Callback to handle download option selections.
 *
 * @returns {JSX.Element} The control panel for setting filters, event type, and download options.
 */
const DashBoardControl = ({ zone, onFilterChange, onEventChange, onDownloadChange }) => {
    // State to manage which control component is currently active
    const [activeComponent, setActiveComponent] = useState(null); // State for the active component

    /* Control Dashboard*/
    const [dashBoardZoneName, setDashBoardZoneName] = useState(false);
    const [dashBoardTimeFrom, setDashBoardTimeFrom] = useState(false);
    const [dashBoardTimeUntil, setDashBoardTimeUntil] = useState(false);
    const [dashBoardTimeOptions, setDashBoardTimeOptions] = useState(false);

    /* Control */
    const [eventStyles, setEventStyles] = useState(false);

    // Toggle active component (for dropdowns and datepickers)
    const handleOpenComponent = (componentName) => {
        setActiveComponent(prev => (prev === componentName ? null : componentName));
    };
    useEffect(() => {
        setDashBoardZoneName(zone.data.features[0].properties.name)
    }, [zone.data.features]);

    // Handle zone name change and pass updated filters to parent
    const handleZoneNameChange = (data) => {

        setDashBoardZoneName(data.name);

        // Handle start date change and pass updated filters to parent
        onFilterChange({
            dashBoardZoneName: data.name, 
            dashBoardTimeFrom, 
            dashBoardTimeUntil,
            dashBoardTimeOptions,
        });
    };

    // Handle start date change and pass updated filters to parent
    const handleTimeFrom = (data) => {
        setDashBoardTimeFrom(data ? data.toISOString().split('T')[0] : false);
        onFilterChange({
            dashBoardZoneName, 
            dashBoardTimeFrom: data ? data.toISOString().split('T')[0] : false, 
            dashBoardTimeUntil,
            dashBoardTimeOptions,
        });
    }

    // Handle end date change and pass updated filters to parent
    const handleTimeUntil = (data) => {
        setDashBoardTimeUntil(data ? data.toISOString().split('T')[0] : false);
        onFilterChange({
            dashBoardZoneName, 
            dashBoardTimeFrom, 
            dashBoardTimeUntil: data ? data.toISOString().split('T')[0] : false,
            dashBoardTimeOptions,
        });
    }

    const handleTimeOptions = (data) => {
        setDashBoardTimeOptions(data.name);
        onFilterChange({
            dashBoardZoneName, 
            dashBoardTimeFrom, 
            dashBoardTimeUntil,
            dashBoardTimeOptions: data.name,
        });
    }

    // Process GeoJSON data to extract zone names
    const processZoneGeoJsonDashboard = (data) => {
        const result = [];
        data.features.forEach(feature => {
            const name = feature.properties.name || 'Unnamed';
            result.push({ "name": name })
        });
        return result;
    };

    const handleEvent = (data) => {
        setEventStyles(data)
        onEventChange(data)
    };

    return (
        <div className='dashboar--control--container' >
            <div className='dashboar--control--event'>
                <Button // Average earthquakes magnitude
                    text="Magnitud de eventos" 
                    operation={() => handleEvent(false)}
                    styles={eventStyles ? "dashboar--control--light" : "dashboar--control--dark"} />
                <Button // Number of earthquakes
                    text="Número de eventos" 
                    operation={() => handleEvent(true)}
                    styles={eventStyles ? "dashboar--control--dark" : "dashboar--control--light"} />
            </div>
            <div className='dashboar--control--filter'>
                {zone && <SelectBox 
                    options={processZoneGeoJsonDashboard(zone.data)}
                    text={zone.data.features[0].properties.name} // extract all zone .features[0].properties.name
                    styles="dashboar--control--select--box zone--name"
                    operation={handleZoneNameChange}
                    isOpen={activeComponent === 'zoneSelectBox'}
                    handleOpen={handleOpenComponent} // Opens selectBox
                    idName="zoneSelectBox"
                    /> }
                <CustomDatepicker 
                    text="Desde (Fecha)" 
                    styles="dashboar--control--custom--Datepicker--from"
                    operation={handleTimeFrom}
                    isOpen={activeComponent === 'datepickerFrom'}
                    handleOpen={handleOpenComponent} // Opens DatePicker 
                    idName='datepickerFrom'
                    />
                <CustomDatepicker 
                    text="Hasta (Fecha)" 
                    styles="dashboar--control--custom--Datepicker--until"
                    operation={handleTimeUntil}
                    isOpen={activeComponent === 'datepickerUntil'}
                    handleOpen={handleOpenComponent} // Opens DatePicker
                    idName='datepickerUntil'
                    />
                <SelectBox 
                    options={controlTimeOptions}
                    text="Diario"
                    styles="dashboar--control--select--box"
                    operation={handleTimeOptions} 
                    isOpen={activeComponent === 'timeSelectBox'}
                    handleOpen={() => handleOpenComponent('timeSelectBox')} // Opens SelectBox 
                    />
                <DropdownButton 
                    text={"descarga".toUpperCase()} 
                    imageSrc={imgDownload} 
                    options={controlDonwload} 
                    onOptionSelect={onDownloadChange}
                    styles="dashboar--control--download" 
                    />
            </div>
        </div>
    )
}

export default DashBoardControl;