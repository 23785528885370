
/* External packages */
import React, { useState } from 'react';

/* Local components */
import EmailInput from '../EmailInput/EmailInput';
import PasswordInput from '../PasswordInput/PasswordInput';
import Button from '../Buttons/Button';

import axiosInstance from '../../utils/api';

/**
 * Authenticates the user by sending email and password to the server.
 * 
 * @param {string} email - User's email address.
 * @param {string} password - User's password.
 * @returns {Promise<boolean>} - Returns true if authentication is successful, false otherwise.
 */
const auth = async (email, password) => {
    try {
        // Send a POST request to the authentication endpoint
        const response = await axiosInstance.post('/auth/token/', {
            "email": email,
            "password": password,
        });

        const { access, refresh } = response.data; // Destructure access and refresh tokens from the response

        // Store tokens in local storage for future use
        sessionStorage.setItem('access', access);
        sessionStorage.setItem('refresh', refresh);

        return true

    } catch (err) {
        console.log('Login failed. Please try again.', err);

        return false
    }
}

/**
 * Login Component
 * 
 * Renders a login form and handles authentication logic.
 * 
 * @param {Object} props - Component properties.
 * @param {function} props.onLogin - Callback function to execute upon successful login.
 * 
 * @returns {JSX.Element} The rendered Login component.
 */
const Login = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        if (event) event.preventDefault(); 

        // Simple validation to check if email and password are provided
        if (!email || !password) {
            setError('Por favor, ingrese su correo electrónico y contraseña.');
            return;
        };

        const status = await auth(email, password);

        // Handle authentication result
        if (status) {
            setError(false);
            onLogin();
        } else {
            setError('Correo electrónico o contraseña incorrectos.');
        }
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleEmailChange = (email, isValid) => {
        if (isValid) {
            setEmail(email)
        }
    };


    return (
        <>
            <h2 className='login--tittle'>Inicia sesión en SISEN</h2>
            <form className='login--form' onSubmit={handleSubmit} autoComplete="on">
                <EmailInput 
                    placeholder="E-mail" 
                    onChange={handleEmailChange}
                    styles="login--input" />
                <PasswordInput 
                    onChange={handlePasswordChange} 
                    placeholder="Contraseña" 
                    styles="login--input" />
                {error ? <p className='error-message' ><b>ERROR: </b> {error} </p> : null}
                <Button 
                    text="Inicia sesión" 
                    operation={() => handleSubmit()}
                    styles="login--submit" />
            </form>
        </>
    );
};

export default Login;
