import React, { useState, useEffect } from 'react';

import Toggle from '../Toggle/Toggle';

/**
 * capitalize Function
 * 
 * Capitalizes the first letter of a string.
 * 
 * @param {string} str - The string to capitalize.
 * @returns {string} The input string with the first letter capitalized, or an empty string if input is invalid.
 */
function capitalize(str) {
    if (typeof str !== 'string' || str.length === 0) {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * LayerControlName Component
 * 
 * Renders a layer control with a toggle and optional image.
 * 
 * @param {Object} props - Component properties.
 * @param {string} props.layerName - The name of the layer.
 * @param {function} props.operation - Callback function to execute when toggling the layer.
 * @param {string} [props.img] - Optional image source for the layer icon.
 * @param {boolean} [props.initialState] - Initial toggle state (default is false).
 * 
 * @returns {JSX.Element} The rendered LayerControlName component.
 */
const LayerControlName = ({ layerName, operation, img = false, initialState = false }) => {
    const [isToggled, setIsToggled] = useState(initialState);

    useEffect(() => {
        // Este efecto se ejecuta cada vez que activeLayers cambie.
        setIsToggled(initialState)
    }, [initialState]); // Dependencia: activeLayers

    const handleToggle = (state) => {
        setIsToggled(!isToggled);
        operation(layerName)
    };

    return (
        <div key={layerName} className='layer--name'>
            <p className={`layer--name--tittle--${isToggled}`}> {capitalize(layerName)} </p>
            <div className='layer--name--end'>
                {img && <img src={img} alt="icon" className='img--arrow' />}
                <Toggle key={layerName} onToggle={handleToggle} initialState={isToggled} />
            </div>
        </div>

    )
}

export default LayerControlName;