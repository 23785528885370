
/* External packages */
import { useState } from 'react';

/* IMG */
import open from "./img/chevron_down.svg";
import closed from "./img/chevron_up.svg";

/**
 * SelectBox Component
 * 
 * This component renders a custom select box with options and handles selection events.
 *
 * @param {Object} props - The properties of the component.
 * @param {Array<Object>} props.options - The array of options to populate in the select box.
 * @param {string} props.text - The default text to display when no option is selected.
 * @param {string} props.styles - Additional CSS classes for styling purposes.
 * @param {function} props.operation - The function to call when an option is selected.
 * @param {string|null} props.parentIdentifier - Optional parent identifier used in the operation function.
 * 
 * @returns {JSX.Element} A custom select box component with dropdown functionality.
 */
const SelectBox = ({ options, text, styles, operation, isOpen, handleOpen, idName }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    /**
     * Handles the selection of an option.
     * @param {Object} option - The selected option object containing `uuid` and `name`.
     */

    const handleSelect = (option) => {
        setSelectedOption(option.name);
        operation(option);
        handleOpen(false);
    };

    const handleOpenControl = () => {
        handleOpen(idName)
    }

    return (
        <div className={`custom--select--container ${styles}`}>
            {/* Display selected option or default text */}
            <div className="custom--select--box" onClick={handleOpenControl}>
                <p className="red--text">{selectedOption || text}</p> 

                {isOpen ? 
                    <img className='custom--select--box--img' src={closed} alt='closed box' /> : 
                    <img className='custom--select--box--img' src={open} alt='open box'/>}
            </div>

            {/* Dropdown list of options */}
            {isOpen && (
                <ul className="custom--select--dropdown">
                {options.map((option, index) => (
                    <li key={index} onClick={() => handleSelect(option)}>
                        {option.img && <img src={option.img} alt="icon" /> }
                        {option.name}
                    </li>
                ))}
                </ul>
            )}
        </div>
    )
};

export default SelectBox;