/**
 * ButtonWithImage Component
 * 
 * This component represents a button with an image and text, providing dynamic styles and behavior.
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.img - The URL of the image to display alongside the button text.
 * @param {string} props.text - The text to display inside the button.
 * @param {function} props.operation - The function to execute when the button is clicked.
 * @param {string} props.styles - Additional style classes to customize the button.
 * @param {boolean} props.isDisabled - Boolean indicating whether the button is disabled.
 *
 * @returns {JSX.Element} A button element with an image and the specified properties.
 */
const ButtonWithImageTooltip = ({ img, text, styles, operation, isDisabled }) => {

    return (
        <div className="button--imagen--tooltip">
            <button 
                className={`button--tooltip button--tooltip--${styles}`} 
                onClick={() => operation()} 
                disabled={isDisabled}>
                    <img src={img} alt="icon" />
            </button>
            <span className={`button--tooltip--text button--tooltip--text--${styles}`}>
                {text}
            </span>
        </div>
    )
};

export default ButtonWithImageTooltip;