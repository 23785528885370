
/* External packages */
import { Routes, Route } from 'react-router-dom';

/* Local components */
import PageMap from './page/PageMap/PageMap';
import PageLogin from './page/PageLogin/PageLogin';
import PageChangePass from './page/PageChangePass/PageChangePass';

/* Styles */
import "./assest/css/styles.css"



function App() {
	return (
		<div className="app--body">
			<Routes>
				<Route path='/' element={<PageLogin />} />
				<Route path='/map' element={<PageMap />} /> 
				<Route path="/reset/:id/:token" element={<PageChangePass />} />
			</Routes>
		</div>
	);
}

export default App;
