import React, { useRef, useEffect }  from 'react'; 
import { Bar } from 'react-chartjs-2';
  
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';


// Registrar los componentes
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
  );

/**
 * Histogram Component
 * 
 * Renders a bar chart and provides functionality to download the chart as an image.
 * 
 * @param {Object} props - Component properties.
 * @param {Array} props.data - Data for the bar chart.
 * @param {Array} props.labels - Labels for the x-axis of the chart.
 * @param {string} props.imgDownload - Format of the image to download ("jpg" or "png").
 * @param {function} props.handleImgDownload - Callback function to handle image download actions.
 * @param {function} props.handleClickGraphi - Callback function for handling clicks on chart elements.
 * @param {string} props.typeData - Type of data displayed in the chart.
 * 
 * @returns {JSX.Element} The rendered Histogram component.
 */
const Histogram = ({ data, labels, imgDownload, handleImgDownload, handleClickGraphi, typeData }) => { 
    const chartRef = useRef(null);

    useEffect(() => {
        if (imgDownload) {
            const chartInstance = chartRef.current; // Access the chart instance

            let imgType = imgDownload === "jpg" ? 'image/jpeg' : 'image/png'

            if (chartInstance) {
                const chartCanvas = chartInstance.canvas; // Get the canvas of the chart

                // Create a new image with a white background
                const width = chartCanvas.width;
                const height = chartCanvas.height;

                // Create a temporary canvas for image creation
                const tempCanvas = document.createElement('canvas');
                tempCanvas.width = width;
                tempCanvas.height = height;
                const tempContext = tempCanvas.getContext('2d');

                // Fill the temporary canvas with white color
                tempContext.fillStyle = '#FFFFFF';
                tempContext.fillRect(0, 0, width, height);

                // Draw the chart onto the temporary canvas
                tempContext.drawImage(chartCanvas, 0, 0);
            
                // Create a link to download the chart as an image
                const link = document.createElement('a');
                link.href = tempCanvas.toDataURL(imgType, 1.0); // Convert the canvas to the specified image format
                link.download = `chart.${imgDownload}`; // Set the filename for download
                link.click(); // Simulate a click on the link to trigger the download
            }

            handleImgDownload();
        }

    }, [imgDownload, handleImgDownload]);

    // Chart data configuration
    const chartData = {
        labels: labels,
        datasets: [
            {
                type: 'bar',
                label: "Eventos",
                data: data,
                backgroundColor: "#451B17",
                borderColor: "#FFFF", 
                borderWidth: 1,
                barThickness: 5, // width column
            },
        ],
    };

    /**
     * formatDate Function
     * 
     * Formats a date string to a more readable format.
     * 
     * @param {string} dateString - The date string to format.
     * @returns {string} Formatted date.
     */
    const formatDate = (dateString) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(Date.parse(dateString + "Z")); // Asegura que se trate como UTC
        return new Intl.DateTimeFormat('es-ES', options).format(date);
    };

    const options = {
        onClick: (event, elements) => {
            if (elements.length > 0) {
                // Get the index of the selected bar
                const index = elements[0].index;
                const chart = chartRef.current;
                const label = chart.data.labels[index];
                handleClickGraphi(label)
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: typeData.toUpperCase(),
                },
            },
            x: {
                title: {
                    display: true,
                    text: ''.toUpperCase(),
                },
                grid: {
                    display: false, // Hide vertical grid lines
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    // Customize tooltip messages
                    title: function () {
                        return ''; // Return an empty string to remove tooltip title
                    },
                    label: function(tooltipItem) {
                        const label = tooltipItem.label; // x-axis label
                        const value = tooltipItem.raw; // Data value
                        const formattedDate = formatDate(label);
                        return [formattedDate,`${typeData}: ${value}`]; // Custom tooltip message
                    },
                },
                backgroundColor: '#FFEFE8', // Tooltip background color
                bodyColor: '#000',
                bodyFont: { size: 14, lineHeight: 1.5  },
                padding: 10,
                displayColors: false, // Remove color box from tooltip
            },
        },
    };

    return (
        <div className='graphics--container'>
            <div className='koko'>
                <Bar 
                    ref={chartRef}
                    data={chartData} 
                    options={options}
                    />
            </div>
        </div>
    );
};

export default Histogram;
