
/* External packages */
import React, { useState, useEffect } from 'react';
import EmailInput from '../EmailInput/EmailInput';

/* Local components */
import Button from '../Buttons/Button';

/* Utils */
import axiosInstance from '../../utils/api';

/**
 * ChangeForgotPassword Component
 * 
 * This component handles the password reset process by allowing users to enter their email
 * address and request a password reset link. It provides feedback based on the state of the email submission.
 *
 * @param {Object} props - The properties of the component.
 * @param {function} props.operation - The function to execute when the user wants to go back or cancel the operation.
 *
 * @returns {JSX.Element} A form element containing email input and buttons for sending the reset link or canceling the action.
 */

const ChangeForgotPassword = ({ operation }) => {

    const [email, setEmail] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [control, setControl] = useState(true);
    const [isSending, setIsSending] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);

    /**
     * Handles changes in the email input field.
     * 
     * @param {string} email - The email entered by the user.
     * @param {boolean} isValid - Indicates if the entered email is valid.
     */

    const handleEmailChange = (email, isValid) => {
        if (isValid) {
            setEmail(email)
        }
    };

    /**
     * Handles the process of sending the password reset email.
     * 
     * If a valid email is provided, it sends a request to the server. 
     * If the request is successful, it updates the state to reflect that 
     * the email has been sent. If the email is invalid, it sets control to false.
     */
    const handleSendEmail = async () => {
        if (email) {
            setIsSending(true); // Deshabilita el botón
            setTimeLeft(60);
            setSendEmail(true)
        
            try {
                await axiosInstance.post('/request-reset-password/', {
                    email: email,
                });
        
                console.log('Email enviado correctamente');
            } catch (err) {
                console.log('Error al enviar email:', err);
            }
        }
        setControl(false)
    };

    useEffect(() => {
        let timer;
    
        if (isSending && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft((prev) => prev - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            setIsSending(false); // Reactivar el botón cuando el contador llegue a 0
        }
    
        // Limpiar el temporizador cuando el componente se desmonte
        return () => clearInterval(timer);
      }, [isSending, timeLeft]);
    
    return (
        <>
            {!sendEmail ? 
                <>
                    <h2 className='forgot--password--tittle'>
                        Cambio de contraseña
                    </h2>
                    <p className='forgot--password--text'>
                        Por favor, proporcione su dirección de correo electrónico para enviarle un enlace donde podrá cambiar su contraseña.
                    </p>
                    <EmailInput placeholder="E-mail" onChange={handleEmailChange} styles="forgot--password--input" control={control} />
                    <Button text="Enviar link" operation={handleSendEmail} styles="forgot--password--submit" /> 
                </> :
                <> 
                    <h2 className='forgot--password--tittle'>
                        El enlace para Restablecer Contraseña ha sido enviado
                    </h2>
                    <p className='forgot--password--text'>
                        Hemos enviado un enlace a su dirección de correo electrónico para restablecer su contraseña.
                        <br /> <br />
                        Por favor, revise su bandeja de entrada y haga clic en el enlace proporcionado para completar el proceso de restablecimiento de su contraseña.
                        <br /> <br />
                        Si no ve el correo en su bandeja de entrada, le recomendamos revisar su carpeta de spam o correo no deseado.
                        <br /> <br />
                        Recuerde que, por seguridad, el enlace tiene una validez de 10 minutos.
                    </p>

                    {!isSending ?
                        <Button text="ENVIAR ENLACE NUEVAMENTE" operation={handleSendEmail} styles="forgot--password--send" isDisabled={isSending} /> :
                        <Button 
                            text={`Enlace enviado,espera ${timeLeft} s para enviar enlace nuevamente`} 
                            operation={() => {}} 
                            styles="forgot--password--send forgot--password--send--disabled" 
                            isDisabled={isSending} />
                    }  
                </>
            }
            <Button 
                text={sendEmail ? "Volver a Inicio" : "Cancelar" }
                operation={() => operation()}
                styles={`forgot--password--${sendEmail}`} />
        </>
    )
}

export default ChangeForgotPassword;