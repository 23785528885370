/* External packages */
import React, { useEffect } from 'react';

import LayerControlName from '../LayerControlName/LayerControlName';

/* Img */
import arrow from "../../assest/img/arrow.svg";
import imgClosed from "../../assest/img/closed.svg";

/**
 * LayersControl Component
 * 
 * Renders a control panel for managing layers, including toggles for visibility.
 * 
 * @param {Object} props - Component properties.
 * @param {Object} props.layerData - An object containing data about the layers.
 * @param {function} props.operation - Callback function to execute when a layer is toggled.
 * @param {function} props.visible - Callback function to manage visibility of the control.
 * @param {Array<string>} props.activeLayers - Array of active layer names.
 * 
 * @returns {JSX.Element} The rendered LayersControl component.
 */
const LayersControl = ({ layerData, operation, visible, activeLayers}) => {

    useEffect(() => {
        return;
    }, [activeLayers]);

    return (
        <div className="layer--control">
            <div className='layer--control--tittle'>
                <h4 className='layer--control--tittle--name' >Capas</h4>
                <img src={imgClosed} alt="closed" onClick={visible}/>
            </div>
            {layerData ? Object.keys(layerData).sort().map((layerName, index) => (
                <div key={layerName} >
                    <LayerControlName 
                        key={layerName} 
                        layerName={layerData[layerName].label} 
                        operation={() => operation(layerName)} 
                        img={arrow} 
                        initialState={activeLayers.includes(layerName)}
                        />
                    {index < Object.keys(layerData).length - 1 && <hr className="layer--control--divider" /> }
                </div>
            )) : <div> No data </div>}
        </div>
    );
}

export default LayersControl;