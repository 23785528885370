
/* External packages */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


/* Local components */
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import Button from '../../components/Buttons/Button';

/* Utils */
import axiosInstance from "../../utils/api";

const PageChangePass = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Obtener los parámetros id y token de la URL
    const { id, token } = useParams();

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    }

    const confirmPasswordChange = async () => {
        if (password === confirmPassword) {
            // call back
            await axiosInstance.post('/set-new-password/', {
                "uid": id,
                "token": token,
                "new_password": password,
            })
            navigate("/")
        } else {
            setError("Contraseñas no coincide");
        }
    }

    return (
        <div className='page--change--pass'>
            <div className="change--pass--container">
                <h2 className='change--pass--tittle'>Cambio de contraseña</h2>
                <div>
                    <p className='change--pass--text'>
                        Para completar el proceso de restablecimiento de su contraseña, 
                        por favor ingrese y confirme su nueva contraseña en los campos proporcionados.
                        <br /> <br />
                        Asegúrese de que ambas contraseñas coincidan antes de proceder. 
                    </p>
                </div>
                <PasswordInput 
                    onChange={handlePasswordChange} 
                    placeholder="Nueva contraseña" 
                    styles="change--pass--input" /> 
                <PasswordInput 
                    onChange={handleConfirmPasswordChange} 
                    placeholder="Confirma tu contraseña" 
                    styles="change--pass--input" /> 
                {error ? <p className='error-message' ><b>ERROR: </b> {error} </p> : null}
                <Button 
                    text="Cambiar contraseña"
                    operation={confirmPasswordChange}
                    styles="change--pass" />
                <Button 
                    text={"Cancelar" }
                    styles="change--pass--cancel" />
            </div>
        </div>
    );
};

export default PageChangePass;
