/* External packages */
import React, { useState, useEffect } from 'react'; //, useRef

/* Local components */
import Histogram from '../GraphicsChart/GraphicsChart';
import DashBoardControl from '../DashBoardControl/DashBoardControl';
import DashBoradTable from '../DashBoardTable/DashBoardTable.jsx';


/* Utils */
import axiosInstance from "../../utils/api.js"

/* Img */
import imgClosed from "../../assest/img/closed.svg";

/**
 * groupByPeriod Function
 * 
 * This function groups earthquake data from a GeoJSON object by a specified time period,
 * organizing them daily, monthly, or yearly based on a given date and time property.
 * It processes each feature in the GeoJSON data, reformats the date, and groups the 
 * magnitudes by the specified period.
 *
 * @param {Object} geojson - A GeoJSON object containing earthquake data.
 * @param {string} period - The period to group data by. Accepts 'Diario' (daily), 
 *                           'Mensual' (monthly), or 'Anual' (yearly).
 *
 * @returns {Object} An object with dates as keys (formatted according to the specified period)
 *                   and arrays of earthquake magnitudes as values, representing all events 
 *                   occurring within that period.
 *
 * Example output structure:
 * {
 *   "2023-10-15": { date: "2023-10-15", magnitudes: [4.2, 3.5] },
 *   "2023-10": { date: "2023-10", magnitudes: [3.9, 5.1, 2.7] },
 *   "2023": { date: "2023", magnitudes: [6.0, 3.8, 4.1] }
 * }
 */

const groupByPeriod = (geojson, period) => {
    const groupedData = {};

    // Iterate over each feature in the GeoJSON data
    geojson.features.forEach((feature) => {
        const fechaHora = feature.properties["event_date"]  //    "Fecha y Hora (UTC)"]
        const [fecha, hora] = fechaHora.split(' | ');

        // Reformat the date from 'DD/MM/YYYY' to 'YYYY-MM-DD'
        const [dia, mes, año] = fecha.split('/');
        const fechaReformateada = `${año}-${mes}-${dia}`;

        // Combine the reformatted date with the time, adding 'Z' to indicate UTC
        const fechaISO = `${fechaReformateada}T${hora}Z`; 
        const eventDate = new Date(fechaISO);
        const magnitude = feature.properties["magnitude"];

        let formattedDate;

        // Format the date based on the selected period: daily, monthly, or yearly
        if (period === 'Diario') {
            formattedDate = eventDate.toISOString().split('T')[0]; // YYYY-MM-DD
        } else if (period === 'Mensual') {
            formattedDate = eventDate.toISOString().slice(0, 7); // YYYY-MM (Mes)
        } else if (period === 'Anual') {
            formattedDate = eventDate.getFullYear().toString(); // YYYY (Año)
        }

        // Initialize the group if it doesn't exist, then add the magnitude
        if (!groupedData[formattedDate]) {
            groupedData[formattedDate] = {
                date: formattedDate,
                magnitudes: []
            };
        }

        // Append the magnitude to the list for the formatted date
        groupedData[formattedDate].magnitudes.push(magnitude);
    });

    return groupedData;
}
 
/**
 * fillMissingPeriods Function
 * 
 * This function generates a list of date-based periods (daily, monthly, or yearly)
 * between a specified start and end date, filling in any missing periods from the provided data.
 * For each period, it checks if there is data available; if not, it creates an empty array 
 * for magnitudes, ensuring continuity in the time series.
 *
 * @param {Object} data - An object containing date keys with earthquake magnitudes grouped by period.
 * @param {string} startDate - The starting date for the period (in 'YYYY-MM-DD' format).
 * @param {string} endDate - The ending date for the period (in 'YYYY-MM-DD' format).
 * @param {string} period - The period for incrementing dates. Accepts 'Diario' (daily), 
 *                           'Mensual' (monthly), or 'Anual' (yearly).
 *
 * @returns {Array} An array of objects, each representing a time period with a `date` key 
 *                  and a `magnitudes` array (containing earthquake magnitudes or an empty 
 *                  array if no data was available for that date).
 *
 * Example output structure:
 * [
 *   { date: "2023-10-15", magnitudes: [4.2] },
 *   { date: "2023-10-16", magnitudes: [] },
 *   ...
 *   { date: "2023-11", magnitudes: [3.5, 5.2] }
 * ]
 */
const fillMissingPeriods = (data, startDate, endDate, period) => {
    // Initialize the start date as a Date object
    let date = new Date(startDate);
    // Prepare an empty array to store the result with all periods
    let result = [];

    // Loop until the current date exceeds the end date
    while (date <= new Date(endDate)) {
        let currentDate;
        
        // Format date based on the period
        if (period === 'Diario') {
            currentDate = date.toISOString().split('T')[0]; // YYYY-MM-DD
        } else if (period === 'Mensual') {
            currentDate = date.toISOString().slice(0, 7); // YYYY-MM
        } else if (period === 'Anual') {
            currentDate = date.getFullYear().toString(); // YYYY
        }

        // Look for the current date in the provided data
        let found = data[currentDate];

        // Add an entry to the result with the date and magnitudes
        // If no data exists for that date, set magnitudes to an empty array
        result.push({
            date: currentDate,
            magnitudes: found ? found.magnitudes : []  
        });

        // Increment the date according to the selected period
        if (period === 'Diario') {
            date.setDate(date.getDate() + 1);  // Increase day
        } else if (period === 'Mensual') {
            date.setMonth(date.getMonth() + 1);  // Increase month
        } else if (period === 'Anual') {
            date.setFullYear(date.getFullYear() + 1);  // Increase year
        }
    }

    return result;
}

/**
 * extractLabelsAndValues Function
 * 
 * This function processes an array of grouped data objects to extract date labels 
 * and associated values, which can either represent the number of events or the 
 * highest magnitude for each date, based on the specified parameter.
 *
 * @param {Array} dataGroupNoMissing - Array of data objects, each containing a `date` 
 *                                      and `magnitudes` array representing magnitudes for that date.
 * @param {boolean} [returnEventCount=false] - If `true`, the function returns the count 
 *                                             of events for each date; otherwise, it returns the highest magnitude.
 *
 * @returns {Object} An object containing `labels` (dates) and `values` (event counts 
 *                   or highest magnitudes) arrays for charting or data analysis.
 *
 * Example output structure:
 * {
 *   labels: ["2023-10-15", "2023-10-16", "2023-10-17"],
 *   values: [2, 0, 4.5]  // Either counts or magnitudes depending on `returnEventCount`
 * }
 */
const extractLabelsAndValues = (dataGroupNoMissing, returnEventCount = false) => {
    // Arrays to store extracted dates and values
    let labels = [];
    let values = [];

    // Loop through each item in the data array
    dataGroupNoMissing.forEach(item => {
        labels.push(item.date); // Adding date to the labes array 
        if (returnEventCount) {
            // If returnEventCount == true, returns the number of events
            values.push(item.magnitudes.length);
        } else {
            // If no events, returns 0 or the highest magnitude (index 0)
            if (item.magnitudes && item.magnitudes.length > 0) {
                values.push( Math.max(...item.magnitudes.map(Number))); // Selects highest magnitude (asumes it is ordered) 
            } else {
                values.push(0); // If no magnitudes, we add 0
            }
        }
    });

    return { labels, values };
};

/**
 * getCurrentAndLastYearDates Function
 * 
 * This function generates an array with two dates: today's date and the date exactly one year ago.
 * Both dates are formatted as 'YYYY-MM-DD' strings for consistent output.
 *
 * @returns {Array} An array containing two strings: the current date and the same date one year prior.
 *
 * Example output:
 * ["2024-10-25", "2023-10-25"]
 */
const getCurrentAndLastYearDates = () => {
    // Returns current date
    const currentDate = new Date();

    // Clones current date and substracts one year 
    const lastYearDate = new Date();
    lastYearDate.setFullYear(currentDate.getFullYear() - 1);

    return [currentDate.toISOString().split('T')[0], lastYearDate.toISOString().split('T')[0]] 
}

/**
 * refreshEarthquakes Function
 * 
 * This asynchronous function makes a GET request to fetch earthquake data based on provided options.
 * If successful, it returns the response data. If there’s an error, it logs the error message to the console.
 *
 * @param {Object} options - The configuration options for the GET request (e.g., URL, query parameters).
 *
 * @returns {Promise<Object>} The response data from the API if successful, otherwise undefined.
 */
const refreshErthquakes = async (options) => { 
    try {
        // Make an API GET request using the provided options
        const response = await axiosInstance.get(options);

        return response.data
    } catch (error) {
        console.error("Error fetching earthquakes");
    }
}
 
/**
 * DashBoard Component
 * 
 * This component displays a dashboard for earthquake data visualization, including filtering options, 
 * graph, and table views. It fetches data from an endpoint based on selected filters (time range, 
 * geographic zone, and data granularity) and allows downloading options for images and data files.
 * 
 * @param {Object} props - The properties of the component.
 * @param {function} props.visible - Toggles the dashboard visibility.
 * @param {string} props.endPoint - API endpoint for fetching earthquake data.
 * @param {Object} props.inZone - GeoJSON object representing the selected zone.
 *
 * @returns {JSX.Element} The dashboard interface with filters, graphs, and tables.
 */
const DashBoard = ({ visible, endPoint, inZone }) => {
    // State to store data and chart information
    const [data, setData] = useState(null);
    const [values, setValues] = useState(null);
    const [labels, setLabels] = useState([]);
    const [filteredZones, setFilteredZones] = useState({});
    const [returnEventCount, setReturnEventCount] = useState(false);
    /* Download */
    const [imgDownload, setImgDownload] = useState(false);
    const [dataDownload, setDataDownload] = useState(false);

    const [selectLabelGraphi, setSelectLabelGraphi] = useState(false);

    // Updates the selected chart label for visualization
    const handleDashboardGraphi = (data) => {
        setSelectLabelGraphi(data);
    }

    // Toggles between event count and highest magnitude display
    const handleDashboardEvent = (data) => {
        setReturnEventCount(data);
    }

    // Initializes the filter settings for the dashboard based on the selected zone
    useEffect(() => {
        const [, lastYearDate]  = getCurrentAndLastYearDates()
        const dashBoardZoneName = inZone.data.features[0].properties.name
        const dashBoardTimeFrom = lastYearDate
        const dashBoardTimeUntil = false //currentDate

        // Set initial filter settings
        setFilteredZones({
            dashBoardZoneName: dashBoardZoneName, 
            dashBoardTimeFrom: dashBoardTimeFrom, 
            dashBoardTimeUntil: dashBoardTimeUntil,
            dashBoardTimeOptions: "Diario",
        });
    }, [inZone]);


    // Update the filtered zone settings based on user input
    const handleZoneDashBoardChange = ({ dashBoardZoneName, dashBoardTimeFrom, dashBoardTimeUntil, dashBoardTimeOptions }) => {
        const newFilteredZones = {
            dashBoardZoneName: dashBoardZoneName || false,
            dashBoardTimeFrom: dashBoardTimeFrom || false,
            dashBoardTimeUntil: dashBoardTimeUntil || false,
            dashBoardTimeOptions: dashBoardTimeOptions || false,
        };

        setFilteredZones(newFilteredZones);
    };
    
    // Fetches earthquake data whenever the endpoint or filters change
    useEffect(() => {
        if (endPoint && filteredZones.dashBoardZoneName) {
            // Construct query parameters for API request
            let options = `${endPoint}/?`;

            const [currentDate, lastYearDate]  = getCurrentAndLastYearDates()

            if (filteredZones.dashBoardTimeFrom) {
                options += `start_date=${filteredZones.dashBoardTimeFrom}`
            } else {
                options += `start_date=${lastYearDate}`
            }

            if (filteredZones.dashBoardTimeUntil) {
                options += `&end_date=${filteredZones.dashBoardTimeUntil}`
            } /* else {
                options += `&end_date=${currentDate}`
            } */
            
            if (filteredZones.dashBoardZoneName) {
                options += `&zone=${filteredZones.dashBoardZoneName}`
            }

            const period = filteredZones.dashBoardTimeOptions || "Diario"

            // Fetch and process earthquake data
            const updateEarthquakeData = async () => {
                const data = await refreshErthquakes(options);
                const dataGroup = groupByPeriod(data, period);

                // Fill missing periods in the grouped data
                const dataGroupNoMissing =  fillMissingPeriods(
                    dataGroup, 
                    filteredZones.dashBoardTimeFrom || lastYearDate, 
                    filteredZones.dashBoardTimeUntil || currentDate, 
                    filteredZones.dashBoardTimeOptions || "Diario",
                );

                // Extract labels and values for charting
                const { labels, values } = extractLabelsAndValues(dataGroupNoMissing, returnEventCount);

                setValues(values);
                setLabels(labels);
                setData(data)
            }

            updateEarthquakeData()
        }
    }, [endPoint, filteredZones, returnEventCount]); //endPoint, filteredZones,  returnEventCount

    // Handles download options for images and data files
    const handleOptionDownload = (option) => {
        if (option === "jpg" || option === "png") {
            setImgDownload(option)
        } else if (option === "csv" || option === "excel") {
            setDataDownload(option)
        }
    };

    // Resets image download state after download is initiated
    const handleImgDownload = () => {
        setImgDownload(false)
    };

    // Resets data download state after download is initiated
    const handleDataDownload = () => {
        setDataDownload(false)
    };

    return (
        <div className='dashboard--container'>
            <div className='dashboard--tittle'>
                <h4 className='dashboard--tittle--name' > Gráficos </h4>
                <img src={imgClosed} alt="closed" onClick={visible}/>
            </div>
            {/* Filter */}
            <div className='dashboard--filter'>
                <DashBoardControl 
                    zone={inZone} 
                    onFilterChange={handleZoneDashBoardChange} 
                    onEventChange={handleDashboardEvent} 
                    onDownloadChange={handleOptionDownload}
                    />
            </div>
            <div className='dashboard--scroll'>
                {/* Graphics */}
                {data && <Histogram 
                            data={values} 
                            labels={labels} 
                            imgDownload={imgDownload} 
                            handleImgDownload={handleImgDownload} 
                            handleClickGraphi={handleDashboardGraphi} 
                            typeData={returnEventCount ? 'Número de eventos' : 'Magnitud de evento'} />}
                {/* Table */}
                {data && <DashBoradTable 
                            geojson={data} 
                            dataDownload={dataDownload} 
                            selectLabelGraphi={selectLabelGraphi} 
                            handleDataDownload={handleDataDownload} />}
            </div> 
        </div>
    );
};

export default DashBoard;