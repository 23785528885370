import React from 'react';
import './LogoutModal.css'; 

/**
 * LogoutModal Component
 * 
 * Displays a modal asking the user to confirm if they want to log out.
 * 
 * @param {Object} props - Component properties.
 * @param {boolean} props.isOpen - Flag indicating whether the modal is open.
 * @param {function} props.onConfirm - Callback function to execute upon confirming logout.
 * @param {function} props.onCancel - Callback function to execute upon canceling logout.
 * 
 * @returns {JSX.Element|null} The rendered modal or null if not open.
 */
const LogoutModal = ({ isOpen, onConfirm, onCancel }) => {
    if (!isOpen) return null; 

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2 className='logout--tittle'>¿Estás seguro de que deseas cerrar sesión?</h2>
                <div className="modal-buttons">
                    <button className="button--logout--cancel" onClick={onCancel}>
                        Cancelar
                    </button>
                    <button className="button--logout--confirm" onClick={onConfirm}>
                        Cerrar sesión
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LogoutModal;
