import React, { useState, useEffect } from 'react';

/**
 * Toggle Component
 * @param {Object} props - Component properties
 * @param {function} props.onToggle - Callback function that is executed when the switch state changes.
 * @param {boolean} props.initialState - Initial state of the switch (on or off). Defaults to `false`.
 * @returns {JSX.Element} Toggle Component.
 */
const Toggle = ({ onToggle, initialState = false }) => {
    const [isToggled, setIsToggled] = useState(initialState);

    useEffect(() => {
        // This effect runs every time activeLayers changes.
        setIsToggled(initialState)
        // Ability to add additional logic if needed when activeLayers changes.
    }, [initialState]); // Dependency: activeLayers

    const handleToggle = (event) => {
        setIsToggled(!isToggled);
        if (onToggle) {
            onToggle(!isToggled);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the button from being activated with Enter
        }
    };

    return (
        <div className={`toggle ${isToggled ? 'toggled' : ''}`} onClick={(event)=> handleToggle(event)} onKeyDown={handleKeyDown} >
            <div className="toggle-button"></div>
        </div>
    );
};

export default Toggle;
