
/* External packages */
import React, { useState } from 'react';

/* Local components */
import SelectBox from '../SelectBox/SelectBox';

/* Img */
import green from "./icon/green.svg";
import yellow from "./icon/yellow.svg";
import orange from "./icon/orange.svg";
import red from "./icon/red.svg";

/**
 * daysAgo
 * @param {number} days - Number of days back from the current date.
 * @returns {string} Date in ISO format of days calculated backwards.
 */
function daysAgo(days){
    const today = new Date();
    const daysAgoDate = new Date(today);
    daysAgoDate.setDate(today.getDate() - Number(days));

    return daysAgoDate.toISOString().split('T')[0];
}

const seismosData = [
    {name: "Seísmos - Todos", value: false},
    {name: "Últimas 24 horas", value: "1"},
    {name: "Última semana", value: "7"},
    {name: "Último mes", value: "30"},
    {name: "Último año", value: "365"},
]

const magnitudData = [
    {name: "Magnitud - Todas", value: false},
    {name: "0-2", value: [0,2], img: green},
    {name: "2-3", value: [2.1,3], img: yellow},
    {name: "3-4", value: [3.1,4], img: orange},
    {name: ">4", value: [4.1], img: red},
]

/**
 * ZoneControl 
 * @param {Object} props - The properties of the component.
 * @param {Object} props.zoneData - Information about the areas .
 * @param {function} props.onZoneChange - Function that is executed when updating a filter.
 * @param {Object} props.earthquakesData - Earthquake information available for filters.
 * @returns {JSX.Element} Zone, time and filter selection component.
 */
const ZoneControl = ({ zoneData, onZoneChange, earthquakesData }) => {

    const [zoneName, setZoneName] = useState(false);
    const [coordinates, setCoordinates] = useState(false);
    const [geometry, setGeometry] = useState(false);
    const [zoneTimeRange, setZoneTimeRange] = useState(false);
    const [magnitudeFilter, setMagnitudeFilter] = useState(false);
    const [activeComponent, setActiveComponent] = useState(null); // Status for active component

    const handleOpenComponent = (componentName) => {
        setActiveComponent(prev => (prev === componentName ? null : componentName));
    };

    const handleZoneNameChange = (data) => {
        let name = false;

        if (data.name !== "Zonas - Todas") {
            name = data.name;
        }

        setZoneName(name);
        setCoordinates(data.coordinates || null);
        setGeometry(data.bbox || null);

        // Passes the updated value correctly
        onZoneChange({ 
            zoneName: name, 
            zoneTimeRange, 
            magnitudeFilter, 
            coordinates: data.coordinates || null, 
            geometry: data.bbox || null 
        });
    };

    const handleTimeRangeChange = (data) => {
        const time = data.value ? daysAgo(data.value) : false;

        setZoneTimeRange(time);

        onZoneChange({ 
            zoneName, 
            zoneTimeRange: time, 
            magnitudeFilter, 
            coordinates, 
            geometry 
        });
    };

    const handleMagnitudeFilterChange = (data) => {
        setMagnitudeFilter(data.value);

        onZoneChange({ 
            zoneName, 
            zoneTimeRange, 
            magnitudeFilter: data.value, 
            coordinates, 
            geometry 
        });
    };

    const processZoneGeoJson = (data) => {
        const result = [{ "name": "Zonas - Todas", "coordinates": [40.077, -3.68]}];

        data.features.forEach(feature => {
            const bbox =  feature.properties.bbox.coordinates;
            const invertedData = bbox.map(innerArray => innerArray.map(coords => [...coords].reverse()));

            const name = feature.properties.name || 'Unnamed';

            result.push({ "name": name, "bbox": invertedData})
        });

        return result;
    }; 

    return (
        <div className='zone--control'>
            {zoneData && <SelectBox 
                options={processZoneGeoJson(zoneData.data)} 
                text="Zonas - Todas" 
                styles={"select--zone--table zone--name"}
                operation={handleZoneNameChange} 
                isOpen={activeComponent === 'zoneName'}
                handleOpen={() => handleOpenComponent('zoneName')} 
                />}
                
            {earthquakesData && <SelectBox 
                options={seismosData}
                text="Seísmos - Todos" 
                styles={"select--zone--table"} 
                operation={handleTimeRangeChange}
                isOpen={activeComponent === 'zoneSeismos'}
                handleOpen={() => handleOpenComponent('zoneSeismos')} 
                />}
            {earthquakesData && <SelectBox 
                options={magnitudData}
                text="Magnitud - Todas"
                styles={"select--zone--table"} 
                operation={handleMagnitudeFilterChange} 
                isOpen={activeComponent === 'zoneMagnitud'}
                handleOpen={() => handleOpenComponent('zoneMagnitud')} 
                />}
        </div>
    );
};
    
export default ZoneControl;