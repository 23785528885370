import React, { useEffect }  from 'react';

import * as XLSX from 'xlsx';

/**
 * downloadCSV Function
 * 
 * Converts an array of data objects into a CSV file and triggers download.
 * 
 * @param {Array<Object>} data - Array of objects to be converted into CSV.
 */
const downloadCSV = (data) => {
    const csvRows = [];

    // Gets properties' header 
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(',')); // Join headers with commas for the first row
    
    data.forEach((item) => {
        const values = headers.map((header) => `"${(item[header] || '').replace(/"/g, '""')}"`);
        csvRows.push(values.join(','));
    });
    
    // Jopins rows in a CSV string
    const csvString = csvRows.join('\n');
  
    // Generates link to download the CSV
    const blob = new Blob(["\uFEFF" + csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'data.csv'; 
    link.click(); 
};

/**
 * downloadExcel Function
 * 
 * Converts an array of data objects into an Excel (.xlsx) file and triggers download.
 * 
 * @param {Array<Object>} data - Array of objects to be converted into an Excel file.
 */
const downloadExcel = (data) => {
    // Creates a new worksheet with th edata from the json
    const ws = XLSX.utils.json_to_sheet(data);
  
    // Creates a new workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Creates xlsx file
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Converts xlsx file to blob ready for the download 
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Creates downlaod link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'data.xlsx';
    link.click();
};

/**
 * s2ab Function
 * 
 * Converts a binary string into an ArrayBuffer for Blob creation.
 * 
 * @param {string} s - Binary string to convert.
 * @returns {ArrayBuffer} - ArrayBuffer for the binary string.
 */
function s2ab(s) {
    const buf = new ArrayBuffer(s.length); // Creates buffer
    const view = new Uint8Array(buf); // Creates view
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; // Converts data
    return buf;
}
  
/**
 * DashBoardTable Component
 * 
 * Displays data in a table format and handles CSV/Excel download options.
 * 
 * @param {Object} props - Component properties.
 * @param {Object} props.geojson - GeoJSON data to be displayed in the table.
 * @param {string} props.dataDownload - Type of file to download (e.g., "csv" or "excel").
 * @param {function} props.handleDataDownload - Callback to reset the download option after download.
 * @param {string} props.selectLabelGraphi - Date filter string (e.g., "YYYY", "YYYY-MM", "YYYY-MM-DD").
 *
 * @returns {JSX.Element} Table component displaying GeoJSON properties with download options.
 */
const DashBoradTable = ({ geojson, dataDownload, handleDataDownload, selectLabelGraphi }) => {
    // Extract feature properties from GeoJSON data
    const featureProperties = geojson.features.map((feature) => ({
        ...feature.properties,
    }));
    const headers = featureProperties.length > 0 ? Object.keys(featureProperties[0]) : [];

    // Handle data download based on the selected format (CSV or Excel)
    useEffect(() => {
        if (dataDownload && featureProperties.length !== 0) {
            if (dataDownload === "csv") {
                downloadCSV(featureProperties)
            } else if (dataDownload === "excel") {
                downloadExcel(featureProperties)
            }

            handleDataDownload()
        }
    }, [dataDownload, handleDataDownload, featureProperties]); // , featureProperties

    /**
     * isDateMatch Function
     * 
     * Checks if a given date matches the selected filter date.
     * 
     * @param {string} featureDate - Date string from the feature properties.
     * @returns {boolean} - True if the date matches the selected filter.
     */
    const isDateMatch = (featureDate) => {
        const datePart = featureDate.split(' | ')[0]; // Extract date part only
        const [day, month, year] = datePart.split('/'); // Split into day, month, year
        
        // Check if selectLabelGraphi matches year only
        if (selectLabelGraphi.length === 4) {
            return year === selectLabelGraphi;
        }

        // Check if selectLabelGraphi matches year and month
        if (selectLabelGraphi.length === 7) {
            const [selectYear, selectMonth] = selectLabelGraphi.split('-');
            return year === selectYear && month === selectMonth;
        }

        // Check if selectLabelGraphi matches year, month, and day
        if (selectLabelGraphi.length === 10) {
            const [selectYear, selectMonth, selectDay] = selectLabelGraphi.split('-');
            return year === selectYear && month === selectMonth && day === selectDay;
        }

        return false; // No match
    };

    if (!geojson || !geojson.features || geojson.features.length === 0) {
        return <p>No GeoJSON data available</p>;
    }

    return (
        <div className="dash--board--table">
            <div className="dash--board--table--header">
                {headers.map((header) => (
                    <p key={header} className={`dash--board--table--header--row column--${header}`}>{geojson.labels[header]}</p>
                ))}
            </div>
            <hr className="layer--control--divider" />
            <div className="dash--board--table--body">
                {featureProperties.map((feature, index) => (
                    <div key={index} >
                        <div className={`dash--board--table--body--row ${isDateMatch(feature["event_date"]) ? 'highlight' : ''}`}>
                            {headers.map((header) => (
                                feature[header] ? 
                                    <p key={header} className={`dash--board--table--body--cell column--${header}`} >{feature[header]}</p>
                                    : <p key={header} className={`dash--board--table--body--cell column--center`} > - </p>
                            ))}
                        </div>
                        {index < featureProperties.length - 1 && <hr className="layer--control--divider" /> }
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DashBoradTable;