
import React, { useState } from 'react';

/**
 * DropdownButton Component
 * 
 * A customizable dropdown button that displays options for selection.
 * 
 * @param {Object} props - Component properties.
 * @param {string} props.text - Text to display on the button.
 * @param {string} props.imageSrc - Source URL for the icon image displayed on the button.
 * @param {Array<Object>} props.options - List of options to display in the dropdown.
 * @param {function} props.onOptionSelect - Callback function to handle option selection.
 * @param {string} props.styles - Additional styles to apply to the button.
 * 
 * @returns {JSX.Element} Dropdown button component.
 */
const DropdownButton = ({ text, imageSrc, options, onOptionSelect, styles }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        onOptionSelect(option);
        setIsOpen(false); // Automatically close dropdown after selection
    };

	return (
		<div className={`dropdown--container`}>
			<button className={`dropdown--button button--${styles}`} onClick={toggleDropdown}>
				<img src={imageSrc} alt="Icono" className="button--image" />
				{text} 
			</button>
			
			{isOpen && (
				<ul className="dropdown--list">
					{options.map((option, index) => (
						<li key={index} onClick={() => handleOptionClick(option.value)} className="dropdown--option">
							{option.name}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default DropdownButton;
