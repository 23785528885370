/**
 * Button Component
 * 
 * This component represents a reusable button with dynamic styles and behavior.
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.text - The text to display inside the button.
 * @param {function} props.operation - The function to execute when the button is clicked.
 * @param {string} props.styles - Additional style classes to customize the button.
 * @param {boolean} props.isDisabled - Boolean indicating whether the button is disabled.
 *
 * @returns {JSX.Element} A button element with the specified properties.
 */
const Button = ({ text, operation, styles, isDisabled }) => {

    return (
        <button 
            type="submit"
            className={`button--main button--${styles}`} 
            onClick={() => operation()} 
            disabled={isDisabled}>
                {text}
        </button>
    )
};

export default Button;