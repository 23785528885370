
/* External packages */
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

/* Local components */
import Button from '../../components/Buttons/Button';
import Login from '../../components/Login/Login';
import ChangeForgotPassword from '../../components/ChangeForgotPassword/ChangeForgotPassword';

/* Utils */
import isTokenExpired from "../../utils/api";

/* Imagen */
import logo from "../../assest/img/logo-name.svg";


const PageLogin = () => {
    const [changePassword, setChangePassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChangeForgotPassword = () => {
        setChangePassword(!changePassword)
    }

    const onLogin = () => {
        setLoading(true)
        navigate("/map")
    }

    useEffect(() => {
        const accessToken = sessionStorage.getItem('access'); // Verifica si el token existe en sessionStorage

        if (accessToken && isTokenExpired(accessToken)) {
            setLoading(true)
            navigate('/map'); // Redirige a /map si el token está presente
        }
    }, [navigate]); // Dependencia en navigate para evitar advertencias de ESLint

    return (
        <>
            {!loading && 
                <div className='page--login'>
                    <div className="login--container">
                        {!changePassword ? 
                            <>
                                <Login onLogin={onLogin} />
                                <Button 
                                    text="¿Olvidaste tu contraseña?" 
                                    operation={handleChangeForgotPassword} 
                                    styles="forgot--password" />
                                <p className='login--note'>Nota: La página web no está optimizada para móviles. Te recomendamos acceder desde un ordenador para mejorar la experiencia de uso.</p>
                            </> :
                            <ChangeForgotPassword operation={handleChangeForgotPassword} />
                        }
                    </div>
                    <img src={logo} alt="Logo" className='logo--name'/>
                </div> 
            }
        </>
    )
}

export default PageLogin;