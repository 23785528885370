
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { es } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';

import open from "./img/chevron_down.svg";
import closed from "./img/chevron_up.svg";
import reset from "./img/closed.svg";

/**
 * CustomDatepicker Component
 * 
 * This component provides a customizable date picker interface, allowing users to select a date
 * and displaying the selected date on a button. It includes functionality for opening and closing
 * the date picker and resetting the selected date.
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.styles - Additional style classes to customize the date picker.
 * @param {string} props.text - Placeholder text displayed when no date is selected.
 * @param {function} props.operation - The function to execute when a date is selected.
 * @param {boolean} props.isOpen - Indicates whether the date picker is currently open.
 * @param {function} props.handleOpen - Function to handle the opening and closing of the date picker.
 * @param {string} props.idName - The identifier used to manage the date picker state.
 *
 * @returns {JSX.Element} A date picker interface with a button for date selection and an embedded calendar.
 */

const CustomDatepicker = ({ styles, text, operation, isOpen, handleOpen, idName }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [startDate, setStartDate] = useState(null);

    /**
     * Handles changes to the selected date.
     * 
     * @param {Date} date - The date selected by the user.
     */
    const handleDateChange = (date) => {
        setStartDate(date);
        setSelectedOption(date ? date.toDateString() : false); // Displays the selected date on the button
        operation(date)
    };

    /**
     * Handles the opening and closing of the date picker.
     */
    const handleOpenControl = () => {
        handleOpen(idName)
    }

    return (
        <div className={`custom--Datepicker--container `}>
            {/* Botón para abrir el calendario */}
            <div className="custom--Datepicker" > 
                <div className={startDate ? 'custom--Datepicker--resalt' : 'custom--Datepicker--default'} onClick={() => handleDateChange(false)} >
                    <p className="red--text">{selectedOption || text}</p>
                    {startDate && <>
                        <img 
                            className='custom--Datepicker--reset' 
                            src={reset} 
                            alt='reset date' />
                        </>}
                    <span className={`button--tooltip--text`}>
                        {"Limpiar filtro"}
                    </span>
                </div>
                {isOpen ? (
                    <img className='custom--Datepicker--img' src={closed} alt='closed box' onClick={handleOpenControl} />
                    ) : (
                    <img className='custom--Datepicker--img' src={open} alt='open box' onClick={handleOpenControl}/>
                )}
            </div>

            {/* Calendario */}
            {isOpen && (
                <div className={`custom--Datepicker--pop ${styles}`} >
                    <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        locale={es}
                        inline // To display the embedded calendar
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                        minDate={new Date(1950, 0, 1)} 
                        maxDate={new Date(2100, 11, 31)} 
                        placeholderText="Selecciona una fecha" />
                </div>
            )}
        </div>
    );
};

export default CustomDatepicker;
