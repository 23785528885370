
import React, { useEffect, useState } from 'react';

/**
 * EmailInput Component
 * 
 * A controlled input for email with validation and optional label.
 * 
 * @param {Object} props - Component properties.
 * @param {string} props.label - Label for the input field.
 * @param {string} props.placeholder - Placeholder text for the input.
 * @param {function} props.onChange - Callback function to handle input change.
 * @param {string} props.styles - Additional styles to apply to the input.
 * @param {string} props.value - Current value of the input.
 * @param {boolean} [props.control=true] - Determines initial validity state of the input.
 * 
 * @returns {JSX.Element} Email input component.
 */
const EmailInput = ({ label, placeholder, onChange, styles, value, control=true }) => {
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(control);

    useEffect(() => {
        setIsValid(control)
    }, [control]); 

    const handleChange = (e) => {
        const value = e.target.value; // Get the current input value
        setEmail(value); // Update the email state

        // Simple email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValid(emailRegex.test(value));
        
        if (onChange) {
            onChange(value, emailRegex.test(value));
        }
    };

    const onKeyDownHandler = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Disable the default action for Enter key
        }
    };

    return (
        <div className="email-input-container">
            {label && <label>{label}</label>}
            <input
                type='email'
                name='email'
                autoComplete="email" 
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
                onKeyDown={onKeyDownHandler}
                className={`${styles} ${isValid ? '' : 'invalid'}  login--focus--${email ? true : false}`}
                required
            />
            {!isValid && <p className="error-message">Por favor ingrese un correo electrónico válido.</p>}
        </div>
    );
};

export default EmailInput;
