
import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap , useMapEvents, ZoomControl, ScaleControl  } from 'react-leaflet';
import L from 'leaflet';

import green from "./icon/green.svg";
import yellow from "./icon/yellow.svg";
import orange from "./icon/orange.svg";
import red from "./icon/red.svg";
import well from "./icon/well.svg";
import iconDefault from "./icon/iconDefault.png";

/**
 * MapEventsHandler - Component to handle map events, in this case, the position of the pointer.
 * @param {function} setPointerCoords - Function to update the coordinates of the pointer.
 */
const MapEventsHandler = ({ setPointerCoords }) => { 
	useMapEvents({
		mousemove: (event) => {
			setPointerCoords([event.latlng.lat, event.latlng.lng]);
		},
	});

	return null;
};

// Function to determine color based on value
const getIconColor = (value) => {
	if (value >= 0 && value <= 2) {
		return green;
	} else if (value > 2 && value <= 3) {
		return yellow;
	} else if (value > 3 && value <= 4) {
		return orange;
	} else {
		return red;
	}
};

const getIcon = (value, layerName) => {
	let icon;

	if (layerName === "earthquakes") {
		icon = getIconColor(value); // Suponiendo que getColor retorna un color en HEX
	} else if (layerName === "wells") {
		icon = well;
	} else {
		icon = iconDefault;
	}

    return L.icon({
        iconUrl: icon, // Here you should have icon images in various colors
        iconSize: [25, 41], // Icon size
    });
};

const FlyToLocation = ({ coordinates, zoom = 6 }) => {
	const map = useMap();
  
	useEffect(() => {
		if (coordinates) {
			map.flyTo(coordinates, zoom ? zoom : map.getZoom());  // Run flyTo when coordinates change
		}
	}, [map, coordinates, zoom]);
  
	return null;
};

const FlyToBBox = ({ bbox }) => {
	const map = useMap();
  
	useEffect(() => {
		if (bbox) {
			// Apply the bbox with an animation similar to `flyTo`
			map.flyToBounds(bbox, { duration: 1.5 }); // Animation duration (in seconds)
		}
	}, [bbox, map]);
  
	return null;
};
 
/**
 * MapLeaflet 
 * @param {Object} activeLayers - Active layers to show on the map.
 * @param {Array} coordinates - Coordinates to center the map [lat, lng].
 * @param {Array} geometry - Geometry of the area to center (bounding box).
 * @returns {JSX.Element} Interactive map with layer control, coordinates and bounding box.
 */
const MapLeaflet = ({ activeLayers, coordinates, geometry }) => {
	const [pointerCoords, setPointerCoords] = useState([0, 0]);

	const onEachFeature = (layerName) => (feature, layer) => {
		if (feature.properties) {
			const content = Object.entries(feature.properties)
				.filter(([key, value]) => key !== "bbox")
				.map(([key, value]) => {
					let label = key;
					for (const layer of Object.values(activeLayers)) {
						if (layer.data?.labels && layer.data.labels[key]) {
							label = layer.data.labels[key];
							break;
						}
					}

					return `<p><strong>${label}:</strong> ${value}</p>`;
				})
				.join('');
	  
			layer.bindPopup(content);
			if (feature.geometry.type === 'Point' || feature.geometry.type === 'MultiPoint') {
				// Assigns a custom icon based on the `magnitude_type` property
				layer.setIcon(getIcon(parseFloat(feature.properties["magnitude"]), layerName));
			}
		}
	};

	const zoomInButton = document.querySelector(".leaflet-control-zoom-in");
	const zoomOutButton = document.querySelector(".leaflet-control-zoom-out");

	if (zoomInButton && zoomOutButton) {
		zoomInButton.removeAttribute("title");
		zoomOutButton.removeAttribute("title");
	}

	return (
		<>
			<MapContainer 
				center={[40.416775, -3.703790]} 
				zoom={6} 
				style={{ height: "100vh", width: "100%" }} 
				zoomControl={false} // Disable zoom control by default
				>
				<TileLayer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				/>
				<ZoomControl position="topright" />
				<ScaleControl position="bottomright" />
				<MapEventsHandler setPointerCoords={setPointerCoords} /> 
				{/* Test data */}
				{activeLayers && Object.keys(activeLayers).map((layerName) => (
					<GeoJSON 
						key={layerName+ JSON.stringify(activeLayers[layerName].data)} 
						data={activeLayers[layerName].data} 
						onEachFeature={onEachFeature(layerName)} />
				))}
				{coordinates && <FlyToLocation coordinates={coordinates} />}
				{geometry && <FlyToBBox bbox={geometry} />}
			</MapContainer>
			<div className="info-box">
				<p>{pointerCoords[0].toFixed(5)}, {pointerCoords[1].toFixed(5)}</p>
			</div>
		</>
	);
};

export default MapLeaflet;
